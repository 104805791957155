import {useNavigation} from "../UseNavigation";

export function NavigationBar() {

    let navigation = useNavigation()

    return (
        <nav>
            <button onClick={navigation.showAdaLinkToggle}
                    className={navigation.showAdaLink && "action-active"}>AdaLink
            </button>
            <button onClick={navigation.showWorkspaceToggle}
                    className={navigation.showWorkspace && "action-active"}>Workspace
            </button>
            <button onClick={navigation.showStorageToggle}
                    className={navigation.showStorage && "action-active"}>Storage
            </button>
            <button onClick={navigation.showSystemToggle} className={navigation.showSystem && "action-active"}>System
            </button>
        </nav>
    )
}