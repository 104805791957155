import React, {useEffect, useState} from "react";
import {equalTo, getDatabase, off, onValue, orderByChild, query, ref} from "firebase/database";
import {firebaseApp} from "../../../Service/Connection";
import {useIntentHosting} from "../Service/UseIntentHosting";
import {useSystem} from "../../../Service/UseSystem";
import {InputText} from "../../../Form";
import {generateUUID} from "../../../Framework/PDAFoundation";
import {View} from "../../../Component";

export function HostingView({hostingId}) {

    let systemHosting = useIntentHosting()
    let actions = systemHosting.actions
    const [editMode, setEditMode] = useState(false)
    const [contact, setContact] = useState(null)
    const [hostnames, setHostnames] = useState(null)

    // Contacts
    useEffect(() => {
        const db = getDatabase(firebaseApp)
        const path = `system/hosting/contacts/${hostingId}`
        const dbRef = ref(db, path)
        onValue(dbRef, (snapshot) => {
            setContact(snapshot.val())
        }, error => {
            //console.error(error)
        })

        return () => {
            off(dbRef)
        }
    }, [hostingId])

    // Hostnames
    useEffect(() => {
        const db = getDatabase(firebaseApp)
        const path = `system/hosting/hostnames/`
        const dbRef = ref(db, path)
        let q = query(dbRef, orderByChild("hostingId"), equalTo(hostingId))
        onValue(q, (snapshot) => {
            if (!snapshot.exists()) {
                return
            }
            setHostnames(snapshot.val())
        }, error => {
            //console.error(error)
        })

        return () => {
            off(dbRef)
        }
    }, [hostingId])

    let title = (contact?.name ?? "")
    let subtittle = "hosting"

    // Edit Mode
    if (editMode) {
        return (
            <View key={hostingId} title={title} subtitle={subtittle}>
                <EditMode hostingId={hostingId} contact={contact} hostnames={hostnames} editMode={setEditMode}/>
            </View>

        )
    }

    // Show Mode
    let actionsElement = []
    if (actions?.canUpdate) {
        actionsElement.push(<button onClick={() => {
            setEditMode(true)
        }}>edit</button>)
    }
    return (
        <View key={hostingId} title={title} subtitle={subtittle} actions={actionsElement}>
            <ShowMode contact={contact} hostnames={hostnames}/>
        </View>
    )
    return (
        <fieldset key={hostingId} className={"text"}>
            <legend>{contact?.name ?? "-"}</legend>
            <div className={"view-actions"}>
                {actions?.canUpdate && <button onClick={() => {
                    setEditMode(!editMode)
                }}>edit</button>}
            </div>
            <ShowMode contact={contact} hostnames={hostnames}/>
        </fieldset>
    )
}

function ShowMode({contact, hostnames}) {

    let html = []
    let system = useSystem()
    if (!contact) {
        return <div>loading...</div>
    }

    if (contact?.name) {
        html.push(<p key={"Name"} className={"text"}>{contact.name}</p>)
    }

    let htmlAddress = []
    if (contact?.address) {
        if (contact?.address?.street) {
            htmlAddress.push(<p key={"Street"}>{contact.address.street}</p>)
        }

        if (contact?.address?.city) {
            htmlAddress.push(<p key={"City"}>{contact.address.city}</p>)
        }
        if (contact?.address?.postalCode) {
            htmlAddress.push(<p key={"PostalCode"}>{contact.address.postalCode}</p>)
        }

        if (contact?.address?.country) {
            htmlAddress.push(<p key={"Country"}>{contact.address.country}</p>)
        }

    }

    html.push(<section key={"htmlAddress"} className={"text"}>{htmlAddress}</section>)

    let htmlRegistration = []

    // Label const by language code
    if (contact.taxId) {
        htmlRegistration.push(<p
            key={"TaxId"}>{system.getLocalizedForLanguage("Tax Id", system.actualLanguage)} {contact.taxId}</p>)
    }

    if (contact.vatId) {
        htmlRegistration.push(<p
            key={"VatId"}>{system.getLocalizedForLanguage("Vat Id", system.actualLanguage)} {contact.vatId}</p>)
    }

    if (contact?.registeredBy) {
        htmlRegistration.push(<p key={"RegisteredBy"}>{contact.registeredBy}</p>)
    }
    html.push(<p key={6} className={'text'}>{htmlRegistration}</p>)

    // Hostnames
    let htmlHostnames = []
    htmlHostnames.push(<div key={"hostnames_title"} className={'secondary-text'}>Hostnames</div>)
    if (hostnames) {
        Object.keys(hostnames).forEach((hostnameId) => {
            htmlHostnames
                .push(<div key={hostnameId} className={'text'}>
                    {hostnames[hostnameId].hostname} [{hostnames[hostnameId].name}]
                </div>)
        })
    } else {
        htmlHostnames.push(<div key={"no_hostnames"}>-</div>)
    }

    html.push(<div key={"hostnames"} className={'text'}>{htmlHostnames}</div>)

    return html;
}

function EditMode({hostingId, contact, hostnames, editMode}) {

    let systemHosting = useIntentHosting()
    let {actions} = systemHosting

    const [newContact, setNewContact] = useState(contact)
    const [newHostnames, setNewHostnames] = useState(hostnames)

    const contactPayload = {
        name: newContact?.name ?? null,
        taxId: newContact?.taxId ?? null,
        vatId: newContact?.vatId ?? null,
        registeredBy: newContact?.registeredBy ?? null,
        address: {
            street: newContact?.address?.street ?? null,
            postalCode: newContact?.address?.postalCode ?? null,
            city: newContact?.address?.city ?? null,
            country: newContact?.address?.country ?? null
        }
    }

    function save() {
        //console.debug("save", newContact, contactPayload)
        systemHosting.intentUpdateHosting(hostingId, contactPayload)
    }

    function deleteContact() {
        //console.debug("delete", newContact, contactPayload)
        systemHosting.intentDeleteHosting(hostingId)
    }

    //console.debug("contact", contact)
    //console.debug("newContact", newContact)
    return (
        <form>
            <div className={"view-actions"}>

                {actions?.canUpdate && <button onClick={() => {
                    save()
                    editMode(false)
                }}>save</button>}

                {actions?.canDelete && <button onClick={() => {
                    deleteContact()
                    editMode(false)
                }
                }>delete</button>}
                {actions?.canUpdate && <button className={"action-active"} onClick={() => {
                    editMode(false)
                }
                }>close</button>}

            </div>
            <InputText key={"name" + hostingId} label={"Name"} value={newContact?.name} onChange={(value) => {
                setNewContact({...newContact, name: value})
            }}/>

            <InputText key={"taxId" + hostingId} label={"Tax ID"} value={newContact?.taxId} onChange={(value) => {
                setNewContact({...newContact, taxId: value})
            }}/>
            <InputText key={"vatId" + hostingId} label={"VAT ID"} value={newContact?.vatId} onChange={(value) => {
                setNewContact({...newContact, vatId: value})
            }}/>
            <InputText key={"registeredBy" + hostingId} label={"Registered By"} value={contact?.registeredBy}
                       onChange={(value) => {
                           setNewContact({...newContact, registeredBy: value})
                       }}/>
            <InputText key={"street" + hostingId} label={"Street"} value={newContact?.address?.street}
                       onChange={(value) => {
                           let newAddress = newContact?.address ?? {}
                           newAddress.street = value
                           setNewContact({...newContact, address: newAddress})
                       }}/>
            <InputText key={"postalCode" + hostingId} label={"Postal Code"} value={newContact?.address?.postalCode}
                       onChange={(value) => {
                           let newAddress = newContact?.address ?? {}
                           newAddress.postalCode = value
                           setNewContact({...newContact, address: newAddress})
                       }}/>
            <InputText key={"city" + hostingId} label={"City"} value={newContact?.address?.city}
                       onChange={(value) => {
                           let newAddress = newContact?.address ?? {}
                           newAddress.city = value
                           setNewContact({...newContact, address: newAddress})
                       }}/>
            <InputText key={"country" + hostingId} label={"Country"} value={newContact?.address?.country}
                       onChange={(value) => {
                           let newAddress = newContact?.address ?? {}
                           newAddress.country = value
                           setNewContact({...newContact, address: newAddress})
                       }}/>

            <section>
                <div className={"subheadline"}>Hostnames</div>
                <div className={"view-actions"}>
                    <button onClick={() => {
                        let newId = generateUUID()
                        let newP = {
                            hostingId: hostingId,
                            hostname: "",
                            name: ""
                        }

                        if (newHostnames) {
                            setNewHostnames({...newHostnames, [newId]: newP})
                        } else {
                            setNewHostnames({[newId]: newP})
                        }
                    }
                    }>add
                    </button>
                </div>
            </section>

            {newHostnames && Object.keys(newHostnames).map((hostnameId) => {
                    return <EditHostname key={hostnameId} hostingId={hostingId} hostnameId={hostnameId}
                                         hostname={newHostnames[hostnameId]}/>
                }
            )}

        </form>
    )
}

function EditHostname({hostingId, hostname, hostnameId}) {

    const [newHostname, setNewHostname] = useState(hostname)
    const [edited, setEdited] = useState(false)

    let systemHosting = useIntentHosting()

    function save() {
        //console.debug("save", newHostname)
        systemHosting.intentUpdateHostname(hostnameId, newHostname)
        setEdited(false)
    }

    function deleteHostname() {
        //console.debug("delete", newHostname)
        //systemHosting.intentDeleteHostname(newHostname)
    }

    if (!hostname) {
        return null
    }

    return (
        <div key={hostnameId} className={"text"}>
            <InputText key={"hostname" + hostnameId} label={"Hostname"} value={newHostname?.hostname}
                       onChange={(value) => {
                           setNewHostname({...newHostname, hostname: value})
                           setEdited(true)
                       }
                       }/>
            <InputText key={"name" + hostnameId} label={"Name"} value={newHostname?.name} onChange={(value) => {
                setNewHostname({...newHostname, name: value})
                setEdited(true)
            }
            }/>
            <div className={"view-actions"}>
                {edited && <button onClick={() => {
                    save()

                }
                }>save
                </button>
                }
                <button onClick={() => {
                    deleteHostname()
                }
                }>delete
                </button>
            </div>
        </div>
    )
}