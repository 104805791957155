import { initializeApp } from "firebase/app"
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyDPuwYRYO74vZ31o01lwkc5gZ53mVADMys",
    authDomain: "cz-pda-001.firebaseapp.com",
    databaseURL: "https://cz-pda-001-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "cz-pda-001",
    storageBucket: "cz-pda-001.appspot.com",
    messagingSenderId: "787630223434",
    appId: "1:787630223434:web:c77143ffb650a3f9d076b3",
    measurementId: "G-VFX1TMNP7P"
};

export const firebaseApp = initializeApp(firebaseConfig);


