import React from "react";

/**
 * Show text as a row
 * @param {string||null} className
 * @param {string} text
 * @param {"info"||"warning"||"error"||null} role
 * @returns {Element}
 */
export function Text({className, role, children}) {

    let roleClassName = "view-item"
    if (className) {
        roleClassName = className
    }

    let iconElement = null

    if (role === "info") {
        iconElement = <i className={"fa fa-info-circle"}/>
        roleClassName = "view-item-info"
    }
    if (role === "warning") {
        iconElement = <i className={"fa fa-exclamation-triangle"}/>
        roleClassName = "view-item-warning"
    }
    if (role === "error") {
        iconElement = <i className={"fa fa-exclamation-circle"}/>
        roleClassName = "view-item-error"
    }

    return (
        <p className={roleClassName}>
            {iconElement && iconElement}
            {children}
        </p>
    )
}