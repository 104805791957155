import React from "react";
import {View, Text, Label} from "../../../Component";
import {useHostingByHostname} from "../Service/UseHostingByHostname";
import {ViewSection} from "../../../Component";


export const HostingProfile = () => {

    let viewKey = "HostingView:Profile"
    let viewTitle = "HostingView Profile"

    let {hosting, contact, error} = useHostingByHostname(window.location.hostname)

    if (error) {
        let content = <Label role={"error"}>{error}</Label>
        return <View key={viewKey} title={viewTitle} message={content}/>
    }
    if (!hosting) {
        let content = <Label role={"info"}>{"Loading..."}</Label>
        return <View key={viewKey} title={viewTitle} message={content}/>
    }

    if (!contact) {
        let content = <Label role={"error"}>{"Cannot find a data. Sorry."}</Label>
        return <View key={viewKey} title={viewTitle} message={content}/>
    }

    let contentHtml = []
    // name
    if (contact.name) {
        viewTitle = contact.name
        contentHtml.push(<h4 key={"Name"}>{contact.name}</h4>)
    }

    let sectionId = []
    // Tax Id
    if (contact.taxId) {
        sectionId.push(<p key={"TaxId"}>{`IČ: ${contact.taxId}`}</p>)
    }
    // Vat Id
    if (contact.vatId) {
        sectionId.push(<p key={"VatId"}>{`DIČ: ${contact.vatId}`}</p>)
    }
    contentHtml.push(
        <section key={"sectionId"}>{sectionId}</section>
    )

    // Address
    let sectionAddress = []

    if (contact?.address) {
        if (contact?.address?.street) {
            sectionAddress.push(<p key={"Street"}>{contact.address.street}</p>)
        }

        if (contact?.address?.city) {
            sectionAddress.push(<p key={"City"}>{contact.address.city}</p>)
        }
        if (contact?.address?.postalCode) {
            sectionAddress.push(<p key={"PostalCode"}>{contact.address.postalCode}</p>)
        }

        if (contact?.address?.country) {
            sectionAddress.push(<p key={"Country"}>{contact.address.country}</p>)
        }
    }

    contentHtml.push(
        <section key={"sectionAddress"}>
            <h4>Adresa</h4>
            {sectionAddress}
        </section>
    )

    // Registered By
    if (contact?.registeredBy) {
        contentHtml.push(<p key={"RegisteredBy"}>{contact.registeredBy}</p>)
    }

    return (
        <View key={viewKey} title={viewTitle} size={"small"}>{contentHtml}</View>
    )
}