import React, {useState} from "react";
import {useSystem} from "../../Service/UseSystem";
import {View, Text} from "../../Component";

export const SignIn = ({className = ""}) => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [signInError, setSignInError] = useState(null)

    const system = useSystem()

    // SignIn
    const handleSignIn = async () => {
        // Check email, password
        if (email === "" || password === "") {
            return
        }

        // Login
        return system.signInByEmailPassword(email, password).then((r) => {
            if (r.error) {
                // inform User, that login failed or User is not registered
                setSignInError("Login failed. Please check your email and password.")
            }

        })
    }

    let formFields = []
    formFields.push(
        <div key={'username'} className={'form-field'}>
            <label>
                Email
            </label>
            <input key="email"
                   id="email"
                   type={"text"}
                   autoComplete={'username'}
                   placeholder="email"
                   onChange={async e => {
                       setSignInError(null)
                       setEmail(e.target.value)
                   }}
            />
        </div>
    )
    formFields.push(
        <div key={'password'} className={'form-field'}>
            <label>
                Password
            </label>
            <input key="password"
                   id="password"
                   type={"password"}
                   autoComplete={'current-password'}
                   ng-hide="true"
                   placeholder="password"
                   onChange={async e => {
                       setSignInError(null)
                       setPassword(e.target.value)
                   }}
                   onKeyUp={async e => {
                       if (e.key === 'Enter') {
                           await handleSignIn()
                       }
                   }}
            />
        </div>
    )

    let formHtml = (
        <form>
            {formFields}
        </form>
    )

    let actionsHtml = (
        <button type="button"
                onClick={() => handleSignIn()}>
            sign in
        </button>
    )

    let messageHtml = []
    if (signInError) {
        messageHtml.push(<Text key={"signInError"} text={signInError} role={"error"}/>)
    }

    return (
        <View key={"SignIn"} title={"Sign In"} actions={actionsHtml} message={messageHtml} size={"small"}>{formHtml}</View>
    )
}