

export function Label({className, role, systemImage, children}) {

    let roleClassName = "label"
    if (className) {
        roleClassName = className
    }

    let iconElement = null

    if (role === "info") {
        iconElement = <i className={"fa fa-info-circle"}/>
        roleClassName = "label-info"
    }
    if (role === "warning") {
        iconElement = <i className={"fa fa-exclamation-triangle"}/>
        roleClassName = "label-warning"
    }
    if (role === "error") {
        iconElement = <i className={"fa fa-exclamation-circle"}/>
        roleClassName = "label-error"
    }

    return (
        <div className={roleClassName}>
            {iconElement && iconElement}
            {children}
        </div>
    )
}