import {useEffect, useState} from "react";
import {equalTo, get, getDatabase, off, orderByChild, query, ref} from "firebase/database";
import {firebaseApp} from "../../../Service/Connection";


/**
 * Get Hosting Name and Contact for hostname
 * @param {string} hostname 'example.com'
 * @returns {{hosting: {} | null, contact: {} | null, error: string | null}}
 *
 * @example
 * const {hosting, contact, error} = useHostingByHostname("example.com")
 */
export function useHostingByHostname(hostname) {

    // Hostname and Name of it
    const [hosting, setHosting] = useState(null)
    // Contact data
    const [contact, setContact] = useState(null)
    // Error message
    const [error, setError] = useState(null)

    let rootPathHostnames = "system/hosting/hostnames"
    let rootPathContacts = "system/hosting/contacts"

    // Get HostnameData
    useEffect(() => {

        if (!hostname) {
            hostname = window.location.hostname
        }

        const db = getDatabase(firebaseApp)
        const dbRef = ref(db, rootPathHostnames)
        let q = query(dbRef, orderByChild("hostname"), equalTo(hostname))

        get(q).then((snapshot) => {
            if (snapshot.exists()) {
                let dataAll = snapshot.val()
                // First record
                let dataFirst = Object.values(dataAll)[0]

                if (!dataFirst) {
                    setError("No data available [1]")
                    return
                }
                // Set hosting data to state
                setHosting(dataFirst)
            } else {
                setError("No data available [2]")
            }
        }).catch((error) => {
            setError(error)
            console.error(error)
        })
        return () => {
            off(q)
        }

    }, [hostname])

    // Get Contacts
    useEffect(() => {
        let db = getDatabase(firebaseApp)
        let hostingId = hosting?.hostingId
        if (!hostingId) {
            setError("No data available [3]")
            return
        }

        let dbRef = ref(db, `${rootPathContacts}/${hostingId}`)
        get(dbRef).then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val()
                setContact(data)
                setError(null)
            } else {
                setError("No data available [4]")
            }
        }).catch((error) => {
            setError(error)
        })
    }, [hosting])

    return {
        hosting,
        contact,
        error
    }
}