import {useEffect, useMemo, useState} from "react";
import {useSystem} from "../../../Service/UseSystem";
import {getDatabase, onValue, ref} from "firebase/database";
import {firebaseApp} from "../../../Service/Connection";
import {emptyIntent, sendGetIntent, sendIntent} from "../../../Intent/SendIntent";


export const useIntentHosting = () => {

    // Actions granted to the current identity
    const [actionsList, setActionsList] = useState([])

    let system = useSystem()
    let identityId = system?.identityId

    let rootPathAuthorization = "/system/authorization/hosting/identities"
    // Get Actions
    useEffect(() => {

        if (!identityId) {
            setActionsList([])
            return
        }

        const db = getDatabase(firebaseApp)
        let path = `${rootPathAuthorization}/${identityId}`
        let dbRef = ref(db, path)
        onValue(dbRef, (snapshot) => {
            ////console.debug(`Snapshot for path: ${path}`, snapshot.exists(), snapshot.val())
            if (snapshot.exists()) {
                setActionsList(Object.keys(snapshot.val()))
            } else {
                setActionsList([])
            }
        }, (error) => {
            console.error(error)
            setActionsList([])
        }, {onlyOnce: true})

    }, [identityId, rootPathAuthorization])

    function intentGetHostingContactSchema() {
        let intent = emptyIntent
        intent.message = "system.hosting.contact.schema.get"
        intent.intent.type = "system.hosting"
        intent.intent.action = "get"
        intent.intent.entity = "hosting.contact.schema"
        intent.intent.hostingId = null
        intent.intent.data = null
        //console.debug(intent, intent.intent)
        return sendGetIntent(intent)
    }

    function intentCreateHosting(payload) {
        let intent = emptyIntent
        intent.message = "system.hosting.contact.create"
        intent.intent.type = "system.hosting"
        intent.intent.action = "create"
        intent.intent.entity = "hosting.contact"
        intent.intent.hostingId = null
        intent.intent.data = payload
        //console.debug(intent, intent.intent)
        sendIntent(intent)
    }

    /**
     * Create Hosting Contact imported from Ares
     * @param hostingId
     * @param taxId
     */
    function intentCreateHostingAres({hostingId, taxId}) {
        let intent = emptyIntent
        intent.message = "system.hosting.contact.create.ares"
        intent.intent.type = "system.hosting"
        intent.intent.action = "create"
        intent.intent.entity = "hosting.contact"
        intent.intent.hostingId = null
        intent.intent.data = {hostingId: hostingId, taxId: taxId, how:"from ares"}
        console.debug(intent, intent.intent)
        sendIntent(intent)
    }

    function intentUpdateHosting(hostingId, payload) {
        let intent = emptyIntent
        intent.message = "system.hosting.contact.create"
        intent.intent.type = "system.hosting"
        intent.intent.action = "update"
        intent.intent.entity = "hosting.contact"
        intent.intent.hostingId = hostingId
        intent.intent.data = payload
        //console.debug(intent, intent.intent)
        sendIntent(intent)
    }

    function intentDeleteHosting(hostingId) {
        let intent = emptyIntent
        intent.message = "system.hosting.contact.delete"
        intent.intent.type = "system.hosting"
        intent.intent.action = "delete"
        intent.intent.entity = "hosting.contact"
        intent.intent.hostingId = hostingId
        intent.intent.data = null
        //console.debug(intent, intent.intent)
        sendIntent(intent)
    }

    function intentUpdateHostname(hostnameId, payload) {
        let intent = emptyIntent
        intent.message = "system.hosting.hostname.update"
        intent.intent.type = "system.hosting"
        intent.intent.action = "update"
        intent.intent.entity = "hosting.hostname"
        intent.intent.hostnameId = hostnameId
        intent.intent.data = payload
        //console.debug(intent, intent.intent)
        sendIntent(intent)
    }

    const actions = useMemo(() => {
        let result = {}
        actionsList.forEach(act => {
                result[act] = act
            }
        )
        return result
    }, [actionsList])

    return {
        actions,
        intentCreateHosting,
        intentCreateHostingAres,
        intentUpdateHosting,
        intentDeleteHosting,
        intentUpdateHostname,
        intentGetHostingContactSchema,
    }
}
