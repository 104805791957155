import {useSystem} from "../Service/UseSystem";


export function SignOutButton() {
    let system = useSystem()
    return (
        <button type="button"  className={"button-view"} onClick={system.signOut}>
            Sign Out
        </button>
    )
}