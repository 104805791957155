import {v4} from "uuid"


/**
 * Return UUID
 * @returns {string}
 */
export function generateUUID() {
    return v4()
}

/**
 * Return clean key for Firebase. Bad things are replaced.
 * @param key
 * @param replacement
 * @returns {*}
 */
export function cleanKey(key, replacement) {
    let rg = /[.#$\[\]\/]/g;
    return key.replace(rg, replacement)
}

// Constant for language available characters
export const languageCharacters = {
    "latinLower": "abcdefghijklmnopqrstuvwxyz",
    "latinUpper": "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    "latinUpperLower": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    "latinUpperLowerNumbers": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    "latinLowerNumbers": "abcdefghijklmnopqrstuvwxyz0123456789"
}


// Generate random string as UUID from characters
export function generateId(length = 12,
                           characters = languageCharacters.latinLowerNumbers) {
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function systemOperator() {
    let hostname = window.location.hostname

    if (hostname.includes("t31.cz")) {
        return {type: "t31", title: "T31"}
    }

    if (hostname.includes("cityholesovice.cz")) {
        return {type: "city", title: "City Holešovice"}
    }

    return {type: "pdat", title: "PDAT"}
}

// Function for localized strings
export function localizedValue(languageCode, en, cz) {
    if (languageCode === "en") {
        return en
    }
    if (languageCode === "cz") {
        return cz
    }
    return en
}