export function VStack({className, children}) {

    let elementClassName = "v-stack"
    if (className) {
        elementClassName = className
    }

    return (
        <div className={elementClassName}>
            {children}
        </div>
    )
}