import React, {Suspense, useEffect} from "react";
import {NavigationBar} from "../../Navigation";
import {useNavigation} from "../../Navigation/UseNavigation";
import {AdaLinksView} from "../../AdaLink/AdaLinksView";
import {SystemView} from "../../System";
import {StoragesView} from "../../Storage/StoragesView";
import {WorkspacesView} from "../../Workspace/WorkspacesView";


// Show Selected Notepad
export function PrivateContent() {

    let navigation = useNavigation()

    const [views, setViews] = React.useState([])


    // ADA LINK View
    useEffect(() => {
        let newView = <AdaLinksView key={"AdaLinksView"}/>
        if (navigation.showAdaLink) {
            setViews([newView, ...views])
        } else {
            let filtered = views.filter((view) => view.key !== newView.key)
            setViews(filtered)
        }
    }, [navigation.showAdaLink])

    // WORKSPACE View
    useEffect(() => {
        let newView = <WorkspacesView key={"WorkspacesView"}/>
        if (navigation.showWorkspace) {
            setViews([newView, ...views])
        } else {
            let filtered = views.filter((view) => view.key !== newView.key)
            setViews(filtered)
        }
    }, [navigation.showWorkspace])

    // STORAGE View
    useEffect(() => {
        let newView = <StoragesView key={"StoragesView"}/>
        if (navigation.showStorage) {
            setViews([newView, ...views])
        } else {
            let filtered = views.filter((view) => view.key !== newView.key)
            setViews(filtered)
        }
    }, [navigation.showStorage])

    // SYSTEM View
    useEffect(() => {
        let newView = <SystemView key={"SystemView"}/>
        if (navigation.showSystem) {
            setViews([newView, ...views])
        } else {
            let filtered = views.filter((view) => view.key !== newView.key)
            setViews(filtered)
        }
    }, [navigation.showSystem])


    return (
        <Suspense fallback={<div>loading ...</div>}>
            <NavigationBar/>
            <main>
                {views}
            </main>
        </Suspense>
    )
}
