import React, {createContext, useContext, useEffect, useState} from "react";
import {firebaseApp} from "./Connection";
import {createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import {getDatabase, onValue, ref} from "firebase/database";

const systemContext = createContext(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function SystemProviderRoot({children}) {
    const system = useSystemProvider();
    return <systemContext.Provider value={system}>{children}</systemContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSystem = () => {
    return useContext(systemContext);
};

// Provider hook that creates auth object and handles state
function useSystemProvider() {

    const noSignInErrors = {
        signIn: null,
        identityExist: null
    };

    // User data registered in Provider
    const [providerUser, setProviderUser] = useState(null);
    // Provider Id from Firebase Authorization
    const [providerId, setProviderId] = useState(null);
    // Identity Id registered in Firebase
    const [identityId, setIdentityId] = useState(null);
    // Identity profile data registered in Firebase
    const [identityIdentification, setIdentityIdentification] = useState(null);
    const [errorSignIn, setErrorSignIn] = useState(noSignInErrors)
    const [localization, setLocalization] = useState(null)
    // Preference for active Identity
    let defaultPreference = {
        Language: 'en',
    }
    const [preference, setPreference] = useState(defaultPreference)

    // Sign in with email and password to Firebase
    const signInByEmailPassword = async (email, password) => {
        const auth = getAuth();
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                setProviderUser(user);
                setIdentityId(user.uid)
                return {user: user, error: null};
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(`${errorCode} - ${errorMessage}`)

                setProviderUser(null)
                setIdentityId(null)

                setErrorSignIn({
                    ...(error),
                    signIn: errorMessage,
                });
                return {user: null, error: error.code};
            });
    };

    const createIdentityByEmail = async (email, password) => {

        const auth = getAuth();

        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                return userCredential.user
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(`${errorCode} - ${errorMessage}`)
                // ..
            });
    };

    // Sign Out from Firebase
    const signOut = async () => {
        const auth = getAuth();
        return auth.signOut()
            .then(() => {
                setProviderUser(null);
                setIdentityId(null);
            });
    };

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setProviderUser(user);
                setProviderId(user.uid);
                // ...
            } else {
                // User is signed out
                setProviderUser(null)
                setProviderId(null)
                setIdentityId(null)
                // ...
            }
        });
    }, []);

    // Get Identity Id from Firebase for Provider Id
    useEffect(() => {

        if (providerId == null) {
            return
        }

        const firebaseDb = getDatabase(firebaseApp)
        const path = `identities/provider/${providerId}/identityId`
        const dbRef = ref(firebaseDb, path)
        console.debug(`Get Identity Id for Provider Id ${providerId} fro path ${path}`)
        const unsubscribe = onValue(dbRef, snapshot => {
            let data = snapshot.val()
            setIdentityId(data)
        }, error => {
            console.error(error)
        })

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [providerId]);

    // Get the identity identification data from Firebase
    useEffect(() => {

        if (identityId == null) {
            return
        }

        const firebaseDb = getDatabase(firebaseApp)
        const dbRef = ref(firebaseDb, `identities/identity/identification/${identityId}`)

        const unsubscribe = onValue(dbRef, snapshot => {
            let data = snapshot.val()
            setIdentityIdentification(data)
        }, error => {
            console.error(error)
        })

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [identityId]);

    // Get the identity preference from Firebase
    useEffect(() => {

        if (!identityId) {
            return
        }

        const firebaseDb = getDatabase(firebaseApp)
        const dbRef = ref(firebaseDb, `identities/identity/preferences/${identityId}`)

        const unsubscribe = onValue(dbRef, snapshot => {
            let data = snapshot.val()
            //  console.debug('Preference', data)
            if (data != null) {
                setPreference(data)
            } else {
                setPreference(defaultPreference)
            }
        }, error => {
            console.error(error)
        })

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [identityId]);

    // Get System Localization from Firebase, and set it to state
    useEffect(() => {

        const firebaseDb = getDatabase(firebaseApp)
        const dbRef = ref(firebaseDb, `system/localization`)

        const unsubscribe = onValue(dbRef, snapshot => {
            //  console.debug('Localization', snapshot.val())
            setLocalization(snapshot.val())
        }, error => {
            console.error(error)
        })

        // Cleanup subscription on unmount
        return () => unsubscribe();

    }, []);

    // Return available languages from Localization
    const getAvailableLanguages = () => {

        let output

        if (localization == null) {
            output = [{code: 'en', name: 'English'}]
            return output;
        }

        if (localization.languages == null) {
            output = [{code: 'en', name: 'English'}]
            return output;
        }

        output = Object.keys(localization.languages).map((code) => {
            return {
                code: code,
                name: localization.languages[code]
            }
        })

        return output;
    }

    // Return the localized data for the active language
    function getLocalized(key) {
        return getLocalizedForLanguage(key, preference.language)
    }

    // Return the localized data for the selected language
    function getLocalizedForLanguage(key, language = 'en') {
        //console.debug('getLocalized', key)
        if (localization == null) {
            // console.debug('getLocalized', 'localization is null')
            return key;
        }

        if (localization.localized == null) {
            // console.debug('getLocalized', 'localization.localized is null')
            return key;
        }
        // clean key is lowercased, without spaces
        let cleanKey = key.toLowerCase().replace(/ /g, '')
        // console.debug('getLocalized', 'cleanKey', cleanKey)

        // get localized data for key
        let localized = localization.localized[cleanKey]
        if (localized == null) {
            //  console.debug('getLocalized', 'Localized is null')
            return key;
        }
        // get localized data for language

        if (localized[language] == null) {
            //  console.debug('getLocalized', 'Localized for language is null')
            return key;
        }
        return localized[language];
    }

    // Return value by localization key
    function getLocalizedValue(value) {
        if (value == null) {
            return null;
        }
        if (value[preference.language] != null) {
            return value[preference.language];
        }
        if (value['en'] != null) {
            return value['en'];
        }
        return null;
    }

// Return the user object and auth methods
    return {
        providerUser,
        identityIdentification,
        identityId,
        signInByEmailPassword,
        createIdentityByEmail,
        signOut,
        errorSignIn,
        preference,
        getAvailableLanguages,
        getLocalizedForLanguage,
        getLocalized,
        getLocalizedValue
    };
}

