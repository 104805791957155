import React from "react";
import {firebaseApp} from "../../Service/Connection";
import {getFunctions, httpsCallable} from "firebase/functions";

/*
   Intent = {
        "message": "unknown",
        "intent": {
            "type": "Unknown",
            "name": "Unknown",
            "entities": {},
            "action": "Unknown",
        },
    };
 */
export const emptyIntent = {
    "message": "",
    "intent": {
        "type": "",
        "action": "",
        "entity": "",
        "data": {},
    },
};

export function sendIntent(intent) {
    const functions = getFunctions(firebaseApp, 'europe-west1');
    const intentFunction = httpsCallable(functions,'intent-send');
    intentFunction(intent).then((result) => {
        console.debug("result", result)
        return {result: result.data, error: null};
    }).catch((error) => {
        console.error("error", error)
        return {result: null, error: error};
    })
}

export function sendGetIntent(intent) {
    const functions = getFunctions(firebaseApp, 'europe-west1');
    const intentFunction = httpsCallable(functions,'intent-send');
    return intentFunction(intent)
}