import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SystemProviderRoot} from "./Service/UseSystem";
import {NavigationProviderRoot} from "./Navigation/UseNavigation";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <SystemProviderRoot>
            <NavigationProviderRoot>
                <App/>
            </NavigationProviderRoot>
        </SystemProviderRoot>
    </React.StrictMode>
);

reportWebVitals();
