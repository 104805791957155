import React, {Suspense} from "react";
import {SignIn} from "../../Identity";
import {CurrentHosting} from "../../System";
import {LastPribor} from "../../System";
import {HostingProfile} from "../../System/Hosting";


export function PublicContent() {

    return (
        <Suspense fallback={<div>loading ...</div>}>
            <main className={'container'}>
                <HostingProfile/>
                <LastPribor/>
                <SignIn/>
            </main>
        </Suspense>
    )
}
