import React from "react";
import './scss/application.scss'
import {useSystem} from "./Service/UseSystem";
import {PublicContent, PrivateContent} from "./Content"
import {useHostingByHostname} from "./System/Hosting/Service/UseHostingByHostname";

function App() {

    let system = useSystem();
    let hostname = "p.d.a.";
    let hosting = useHostingByHostname();

    if (hosting?.hosting?.name) {
        hostname = hosting.hosting.name;
    }


    document.title = hostname;
    document.description = hostname;

    if (system.identityId) {
        return <PrivateContent/>
    }

    return <PublicContent />
}

export default App;
