import React, {useEffect, useState} from "react";
import {useSystem} from "../../Service/UseSystem";


// React Component: Input text field, localized, changed parent state, parent change state
export const InputText = ({
                              className = "",
                              label = "",
                              value = '',
                              maxLength = null,
                              onEnterKey = null,
                              onChange = null
                          }) => {

    let system = useSystem()
    let localizedLabel = system.getLocalized(label)
    const [text, setText] = useState(value)

    useEffect(() => {
        setText(value)
    }, [value])

    const handleChange = (event) => {
        setText(event.target.value)
        if (onChange) {
            onChange(event.target.value)
        }
    }

    const handleKeyDown = (e) => {
        if (onEnterKey && e.key === 'Enter') {
            onEnterKey()
        }
    }

    return (
        <div className={className}>
            <label>{localizedLabel}</label>
            <input type="text" value={text} onChange={handleChange} onKeyDown={handleKeyDown} maxLength={maxLength}/>
        </div>
    )
}