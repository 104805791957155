import React, {useEffect, useState} from "react";
import {getDatabase, limitToLast, off, onValue, orderByKey, query, ref} from "firebase/database";
import {firebaseApp} from "../../../Service/Connection";
import {View, Text} from "../../../Component";

export function LastPribor() {
    const [pribor, setPribor] = useState(null)
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    // Contacts
    useEffect(() => {
        const db = getDatabase(firebaseApp)
        const path = `system/CNB/pribor`
        const dbRef = ref(db, path)
        const queryRef = query(dbRef, orderByKey(), limitToLast(1))
        onValue(queryRef, (snapshot) => {
            if (snapshot.exists()) {
                setPribor(snapshot.val())
                setLoading(false)
                setError(false)
            } else {
                setError("No data available")
                setLoading(false)
            }
        }, error => {
            console.error(error)
            setError("No data available")
            setLoading(false)
        })

        return () => {
            off(dbRef)
        }
    }, [])

    if (loading) {
        let content = <Text text={"Loading..."} role={"info"}/>
        return <View title={"Pribor"} content={content}/>
    }

    if (error) {
        let content = <Text text={error} role={"error"}/>
        return <View title={"Pribor"} content={content}/>
    }

    // Make Table
    let tableRows = []
    let tableCaption = ""
    //
    for (let date in pribor) {
        tableCaption = date
        let rows = pribor[date]
        for (let period in rows) {
            let value = rows[period]
            // 2 decimal places and percent
            let valuePercent = (value).toFixed(2) + " %"
            //console.debug(date, period, value)
            let key = date + ":" + period
            tableRows.push(
                <tr key={key}>
                    <td>{period}</td>
                    <td className={"value"}>{valuePercent}</td>
                </tr>
            )
        }
    }

    let table = (
        <table>
            <caption>{tableCaption}</caption>
            <thead>
            <tr>
                <th>Period</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
        </table>
    )
    // Footer
    let footer = (
            <p>Zdroj: <a
                href={"https://www.cnb.cz/cs/financni-trhy/penezni-trh/pribor/fixing-urokovych-sazeb-na-mezibankovnim-trhu-depozit-pribor/index.html"}
                target={"_blank"}
                rel={"noreferrer"}
            >Česká Národní Banka</a></p>
    )

    return (
        <View title={"Pribor"} footer={footer} size={"small"}>{table}</View>
    )
}