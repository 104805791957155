import {SignOutButton} from "../../Identity";
import {HostingProfile, HostingsView} from "../Hosting";


export function SystemView() {
    return (
        <>
            <HostingsView/>
            <HostingProfile/>
            <SignOutButton/>
        </>
    )
}