import {useState} from "react";
import {Label, View} from "../../../Component";
import {InputText} from "../../../Form";
import {useIntentHosting} from "../Service/UseIntentHosting";

/**
 * Get Value is taxId and send intent to create hosting contact
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function HostingCreateButton() {

    const [value, setValue] = useState("")
    const [message, setMessage] = useState(null)

    let intentHosting = useIntentHosting()

    const handleChange = (taxId) => {
        setValue(taxId)
        setMessage(null)
    }

    // reset after 2 seconds
    const reset = () => {
        setTimeout(() => {
            setValue("")
            setMessage(null)
        }, 2000)
    }

    const handleSend = () => {
        console.debug("handleSend", value)
        // Send intent
        // useIntentHosting.intentCreateHosting({taxId: value})
        // Check if value is only number and 7-8 chars
        if (value.length < 7 || value.length > 8 || isNaN(value)) {
            let error = "Tax ID must be 7-8 digits"
            setMessage(<Label role={"error"}>{error}</Label>)
            return
        }
        setMessage(<Label role={"info"}>Creating hosting contact</Label>)
        // Send Intent
        if (intentHosting) {
            intentHosting.intentCreateHostingAres({taxId: value})
        }
        reset()
    }

    return (
        <View key={"create:hosting;ares"} size={"small"} title={"Create Hosting from Ares"} message={message}>
            <InputText value={value} label={"Tax ID"} onChange={handleChange} onEnterKey={handleSend} maxLength={8}/>
            <button onClick={handleSend}>create hosting contact</button>
        </View>

    )
}