import React from "react";
import {VStack} from "../VStack";

/**
 * View component
 * @param {string||null} title
 * @param {string||null} subtitle
 * @param {[]||string||null} actions
 * @param {[]||string||null} message
 * @param {[]||string||any||null} footer
 * @param {"small-x"||"small"||"medium"||"large"||"large-x"||null} size
 * @param {function||null} onDismissEvent
 * @param {[]||string||null}children
 * @returns {Element}
 * @constructor
 */
export function View({title, subtitle, actions, message, footer, size, onDismissEvent, children}) {

    const [showContent, setShowContent] = React.useState(false)

    // Set size
    let sizeClassName = "view-medium"
    if (size === "small-x") {
        sizeClassName = "view-small-x"
    }
    if (size === "small") {
        sizeClassName = "view-small"
    }
    if (size === "large") {
        sizeClassName = "view-large"
    }
    if (size === "large-x") {
        sizeClassName = "view-large-x"
    }

    // Show content toggle
    function showContentToggle() {
        setShowContent(!showContent)
    }

    // Dismiss button
    let dismissButton = null
    if (onDismissEvent) {
        dismissButton = <button type={"button"} onClick={onDismissEvent}><i className={"fa fa-times"}/></button>
    }
    //
    if (showContent) {
        return (
            <article className={sizeClassName}>
                <header className={"view-header"}>
                    <VStack>
                        <h3>{title}</h3>
                        {subtitle && <div className={'subheadline'}>[{subtitle}]</div>}
                    </VStack>
                    <div className={"actions"}>
                        <button type={"button"} onClick={showContentToggle}>
                            {showContent ? <i className={"fa fa-chevron-up"}/> : <i className={"fa fa-chevron-down"}/>}
                        </button>
                        {dismissButton && dismissButton}
                    </div>
                </header>
                {children && <div className={"view-actions"}>{actions}</div>}
                {message && message}
                {showContent && <div className={"view-content"}>{children}</div>}
                {footer && <footer className={"view-footer caption1"}>{footer}</footer>}
            </article>
        )
    }
    //
    return (
        <button className={"button-view"} onClick={showContentToggle}>
            {title}
            {subtitle && <div className={'subheadline'}>[{subtitle}]</div>}
        </button>
    )
}

