import React, {createContext, useContext, useState} from "react";

const navigationContext = createContext(undefined);

export function NavigationProviderRoot({children}) {
    const system = useNavigationProvider();
    return <navigationContext.Provider value={system}>{children}</navigationContext.Provider>;
}

export const useNavigation = () => {
    return useContext(navigationContext);
};

export const useNavigationProvider = () => {
    const [showSystem, setShowSystem] = useState(false)
    const [showStorage, setShowStorage] = useState(false)
    const [showAdaLink, setShowAdaLink] = useState(false)
    const [showWorkspace, setShowWorkspace] = useState(false)


    function showSystemToggle() {
        setShowSystem(!showSystem)
    }

    function showStorageToggle() {
        setShowStorage(!showStorage)
    }

    function showAdaLinkToggle() {
        setShowAdaLink(!showAdaLink)
    }

    function showWorkspaceToggle() {
        setShowWorkspace(!showWorkspace)
    }
    //
    // function toggleNotebook(notebookId) {
    //     // if not in array, add it
    //     if (!selectedNotebooks.includes(notebookId)) {
    //         setSelectedNotebooks([...selectedNotebooks, notebookId])
    //     } else {
    //         // remove it
    //         setSelectedNotebooks(selectedNotebooks.filter((id) => id !== notebookId))
    //     }
    // }

    return {
        showSystem,
        showSystemToggle,
        showStorage,
        showStorageToggle,
        showAdaLink,
        showAdaLinkToggle,
        showWorkspace,
        showWorkspaceToggle,
    }

}
