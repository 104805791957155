import React, {useEffect} from "react";
import {getDatabase, off, onValue, ref} from "firebase/database";
import {firebaseApp} from "../../../Service/Connection";
import {HostingContactEdit} from "../HostingContactEdit";
import {useIntentHosting} from "../Service/UseIntentHosting";
import {HostingView} from "../HostingView";
import {HostingCreateButton} from "../HostingCreateButton";

export function HostingsView() {

    const [hostings, setHostings] = React.useState([]);
    const [newContact, setNewContact] = React.useState(null);

    let actions = useIntentHosting().actions

    // Get HostingsView
    useEffect(() => {
        if (!(actions?.canRead)) {
            //console.error("No permission to read hostings")
            return
        }
        const db = getDatabase(firebaseApp)
        const path = "system/hosting/contacts"
        const dbRef = ref(db, path)
        onValue(dbRef, (snapshot) => {
            //console.debug("snapshot", snapshot.val())
            if (snapshot.exists()) {
                //console.debug("hostings fetched")
                let data = snapshot.val()
                // Add HostingView Id to data
                setHostings(Object.values(Object.keys(data)))
            } else {
                //console.error("No data available", path)
            }
        })

        return () => {
            //console.debug("off", path)
            off(dbRef)
        }
    }, [actions?.canRead])

    if (!(actions?.canRead)) {
        //console.error("No permission to read hosting")
        return null
    }

    let hostingsViews = []

    // New Hosting

    if (actions?.canCreate) {
        hostingsViews.push(
             <HostingCreateButton/>
        )
    }

    hostings.forEach((hostingId, index) => {
        hostingsViews.push(
            <HostingView key={index} hostingId={hostingId}/>
        )
    })

    return hostingsViews

    return (
        <fieldset className={"text"}>
            <legend>Hosting</legend>
            <div className={"view-actions"}>
                {actions?.canCreate && <button onClick={() => {
                    setNewContact(true)
                }}>new contact
                </button>}
            </div>
            {newContact && <HostingContactEdit isNew={true} contactId={null} showEdit={setNewContact}/>}
            {hostings.map((hostingId, index) => {
                return (
                    <HostingView key={index} hostingId={hostingId}/>
                )
            })
            }
        </fieldset>
    )
}
