import React from "react";

export const AccountProfile = () => {

    return (
        <div className={'view-medium'}>
            <table>
                <caption>Account Profile</caption>
                <thead>
                <tr>
                    <th>Key</th>
                    <th>value</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Account Id</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Code</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Name [cs]</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Name [en]</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Type</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Status</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Created</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Updated</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Created By</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Account Updated By</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
        ;
}